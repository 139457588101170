
/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/

/*.MuiAutocomplete-root .MuiFormControl-root{*/
/*  margin: unset;*/
/*}*/


/* Removing the up and down arrows in TextField of Material UI */
/* (Chrome, Safari, Edge, Opera) */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* (Firefox) */
input[type=number] {
  -moz-appearance: textfield;
}


.tooltip-bar-chart{
  display: grid!important;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 30px);
}

::slotted(div) {
  overflow: hidden;
}

.apexcharts-canvas{
  position: absolute!important;
}

.apextooltip-custom{
  z-index: 9999;
}

.react-tel-input .selected-flag {
  height: 60%;
}

.react-tel-input .form-control {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  padding: 8px 13px 8px 58px;
  width: 100%;
}

.react-tel-input .special-label {
  color: #6C737F;
  line-height: 1.1em;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  left: 9px;
  padding-left: 4px;
  padding-right: 4px;
}
